let accordion = document.getElementsByClassName('JS-accordion');
console.log(accordion);
let i;

for (i = 0; i < accordion.length; i++) {
    accordion[i].addEventListener("click", function() {
    this.classList.toggle("active");
    let panel = this.querySelector('.s-faq__panel');
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = `${panel.scrollHeight}px`;
    }
  });
}

