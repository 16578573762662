const sliders = document.querySelectorAll('.JS-slider');
sliders.forEach((slider) => {
  const nextEl = slider.querySelector('.c-slider__navigation-btn--next');
  const prevEl = slider.querySelector('.c-slider__navigation-btn--prev');
  const container = slider.querySelector('.swiper');

  const swiper = new Swiper(container, {
    loop: true,
    slidesPerView: 'auto',

    navigation: {
      nextEl: nextEl,
      prevEl: prevEl,
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 20
      }
    }
  });
} );
