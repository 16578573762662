let container = document.querySelector('.JS-hero');
let austronaut = document.querySelector('.o-hero__content-austranaut');
let phone = document.querySelector('.o-hero__content-phone');
let ship = document.querySelector('.o-hero__content-ship');
let moon = document.querySelector('.o-hero__content-moon');

if (window.innerWidth > 992 && container!==0){
    window.addEventListener('mousemove', function(e) {
        let x = e.clientX / window.innerWidth;
        let y = e.clientY / window.innerHeight;  

        austronaut.style.transform = `translate(${x * 50}px, ${y * 50}px)`;
        ship.style.transform = `translate(-${x * 70}px, -${y * 70}px) scale(${1 + x*0.1})`;
        phone.style.transform = `translate(-${x * 50}px, -${y * 40}px)`;
        moon.style.transform = `translate(-${x * 20}px, ${y * 50}px) scale(${1.1 - x*0.1})`;
        
    });
}


window.addEventListener('resize', ()=>{
    if (window.outerWidth < 992){
        austronaut.removeAttribute("style");
        ship.removeAttribute("style");
        phone.removeAttribute("style");
        moon.removeAttribute("style");

    }
})