const loginDrop = document.querySelector('.JS-login');
const loginDropList = document.querySelector('.JS-login-list');
const loginDropListItem = loginDropList.querySelector('.o-header__ligin-item');
const languageDrop = document.querySelector('.JS-language-dropdown');
const languageDropListItem = languageDrop.querySelector('.o-header__language-item');

languageDrop.addEventListener('click', ()=> {
    languageDrop.classList.toggle('active');

});

languageDropListItem.addEventListener("click", ()=> {
    languageDrop.classList.remove('active');
})

//-------------

loginDrop.addEventListener('click', ()=> {
    loginDropList.classList.toggle('active');
    loginDrop.classList.toggle('active');
});

loginDropListItem.addEventListener("click", ()=> {
    loginDropList.classList.remove('active');
    loginDrop.classList.remove('active');
})

window.addEventListener('click', e => {
    const target = e.target
    if (!target.closest('.JS-language-dropdown') ) {
        languageDrop.classList.remove('active');
    } else{
        return
    }

    if (!target.closest('.JS-log-wrap') ) {
        loginDropList.classList.remove('active');
        loginDrop.classList.remove('active');
    } else{
        return
    }
})

//-----------nav

const nav = document.querySelector('.o-nav');
const toggle = document.querySelector('.o-nav__collaps-btn');
const navLink = document.querySelectorAll('.o-nav__link');
toggle.addEventListener('click', ()=> {
    toggle.classList.toggle('active');
    nav.classList.toggle('active');
})
navLink.forEach((link) => {
    link.addEventListener('click', ()=> {
        toggle.classList.remove('active');
        nav.classList.remove('active');
    });
})

window.addEventListener('resize', ()=>{
    if (window.clientWidth > 992){
        toggle.classList.remove('active');
        nav.classList.remove('active');
    }
})