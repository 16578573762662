const section = document.querySelectorAll('section');

section.forEach((section) => {
    const cols = section.querySelectorAll('[class*="col-"]');
    
    cols.forEach((col, index) => {
        col.setAttribute("data-aos-offset", "100");  
        col.setAttribute("data-aos-duration", "1000");  
        if ((index + 1)%2 == 0){
            col.setAttribute("data-aos", "fade-right");  
        } else if(col.classList.contains('col-12') ){
            col.setAttribute("data-aos", "fade-right"); 
        } else {
            col.setAttribute("data-aos", "fade-left");  
        }
    })
});

AOS.init();